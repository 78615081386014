import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/layout/layout'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css';

import '../contact/form.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = { 
      isValidated: false,
      startDate: null,
      endDate: null,
      focusedInput: null,
      dateState: 'loading',
    }
    this.blockedDays = [];
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  componentDidMount() {
    this.getRemoteIcal();
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate })
  }

  onFocusChange(focusedInput) {
    this.setState({
      focusedInput,
    })
  }

  isBlocked = day => {
    return this.blockedDays.includes(day.format('YYYY-MM-DD'));
  }
  
  getRemoteIcal = () => {
    this.blockedDays = [];
    const ical = require('cal-parser');
    const moment = require('moment');
    const url = '/.netlify/functions/ical';
    fetch(url)
      .then(function(response) {
        return response.text();
      }).then((data) => {
        const parsedCal = ical.parseString(data);
        parsedCal.events.forEach(element => {
          let mom = moment(element.dtstart.value);
          this.blockedDays.push(mom.format('YYYY-MM-DD'));
        });
        this.setState({ dateState: 'ready' });
      })
      .catch(function(error) {
        alert('An error occurred retrieving the available dates.');
        console.log(error);
      });
  }

  getLocalTestCalendar = (e) => {

    const data = `BEGIN:VCALENDAR
PRODID:-//Google Inc//Google Calendar 70.9054//EN
VERSION:2.0
CALSCALE:GREGORIAN
METHOD:PUBLISH
X-WR-CALNAME:Casa Koolhof
X-WR-TIMEZONE:Europe/Amsterdam
BEGIN:VEVENT
DTSTART;VALUE=DATE:20200703
DTEND;VALUE=DATE:20200704
DTSTAMP:20200610T203155Z
UID:50r29smg0k74ufv2od1sqh5prf@google.com
CREATED:20200610T203126Z
DESCRIPTION:
LAST-MODIFIED:20200610T203136Z
LOCATION:
SEQUENCE:0
STATUS:CONFIRMED
SUMMARY:bezet
TRANSP:TRANSPARENT
END:VEVENT
BEGIN:VEVENT
DTSTART;VALUE=DATE:20200701
DTEND;VALUE=DATE:20200702
DTSTAMP:20200610T203155Z
UID:75a3b233bgj5qlhrvoabal1s0s@google.com
CREATED:20200610T185214Z
DESCRIPTION:
LAST-MODIFIED:20200610T185214Z
LOCATION:
SEQUENCE:0
STATUS:CONFIRMED
SUMMARY:bezet
TRANSP:TRANSPARENT
END:VEVENT
END:VCALENDAR`;
    const ical = require('cal-parser');
    const moment = require('moment');
    var parsedCal = ical.parseString(data);
    parsedCal.events.forEach(element => {
      let mom = moment(element.dtstart.value);
      this.blockedDays.push(mom.format('YYYY-MM-DD'));
    });
  }
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const button = document.querySelector('button.submit');
    button.innerHTML = 'Moment geduld a.u.b...';
    button.disabled = true;
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <main>
          <section>

            <div className="page contact">
            
              <h1>Beschikbaarheid</h1>
            
                  <fieldset>
                    <legend>Datum</legend>
                    <div className={this.state.dateState}>

                      <div className="loadingText">Loading... Moment geduld a.u.b...</div>
                      <DateRangePicker
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
                        isDayBlocked={this.isBlocked}
                        hideKeyboardShortcutsPanel={true}
                        required={true}
                        orientation="vertical"
                      />
                    </div>

                  </fieldset>

            </div>
          </section>
        </main>
      </Layout>
    )
  }
}
